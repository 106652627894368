import { TEMPERATURE_LAYER } from '../fileds';

export const INFO = [{
  label: '入库单号：',
  prop: 'receiptOrderNo',
}, {
  label: '货主名称：',
  prop: 'shipperName',
}, {
  label: '入库单类型：',
  prop: 'receiptType',
}, {
  label: '备注：',
  prop: 'remark',
  width: 3,
}];
export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
}, {
  label: '商品名称',
  prop: 'goodsName',
  width: 80,
}, {
  label: '温层',
  prop: 'temperatureLayer',
  width: 80,
  formatter: ({ temperatureLayer }) => TEMPERATURE_LAYER[temperatureLayer],
}, {
  label: '规格',
  prop: 'spec',
  width: 120,
}, {
  label: '保质期',
  prop: 'shelfLife',
  width: 160,
}, {
  label: '预约数量',
  prop: 'bookingNumStr',
  width: 120,
}, {
  label: '已验收量',
  prop: 'acceptanceNumStr',
  width: 120,
}, {
  label: '差异量',
  prop: 'diff',
  width: 120,
}];
