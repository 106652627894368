import { ref } from 'vue';
import Moment from '@/utils/moment';

const useFiles = () => {
  const uploadFile = ref([]);
  const loading = ref(false);
  // 查询订单附件列表
  const getAttachmentFiles = (row, fileData = []) => {
    loading.value = true;
    uploadFile.value = fileData.map((item) => ({
      ...item,
      url: item.filePath,
      fileTime: Moment.format(item.updateTime),
      status: 'success',
    }));
  };
  // 单个删除
  const handleRemove = ({ index }) => {
    uploadFile.value = uploadFile.value.filter((item, i) => index !== i);
  };
  // 批量删除
  const handleAllRemove = () => {
    uploadFile.value = [];
  };
  //
  const getFiles = () => {
    let addFile = [];
    addFile = uploadFile.value.map((item) => ({
      attachmentUrl: item.filePath,
      attachmentName: item.fileName,
    }));
    return addFile;
  };
  return {
    uploadFile,
    getAttachmentFiles,
    handleRemove,
    handleAllRemove,
    getFiles,
  };
};

export default useFiles;
