<template>
  <nh-dialog
    v-model="dialogVisible"
    v-loading="loading.init"
    title="差异确认"
    append-to-body
    class="custom-dialog_1250"
  >
    <nh-info-header
      :info="INFO"
      :data="form"
      title="订单基本信息"
      class="dialog-header"
    />
    <div class="title-text-component title--medium">
      货品明细
    </div>
    <PlTable
      v-loading="loading.getReservoirList"
      :data="tableData"
      :columns="TABLECOLUMN"
    />
    <template #footer>
      <div>
        <nh-button
          v-loading="loading.handleSubmit"
          type="primary"
          @click="handleSubmit"
        >
          差异确认
        </nh-button>
      </div>
    </template>
  </nh-dialog>
</template>
<script>
import loadingMixin from '@thales/loading';
import unitNumber from '@/utils/unitNumber';
import { StoreBillTypeText } from '@/constant/task';
import { INFO, TABLECOLUMN } from './fileds';
import { receiptOrderDetail, diffComplete } from '../api';

export default {
  name: 'InWarehouseAcceptanceDiff',
  mixins: [loadingMixin],
  emits: ['onSucceed'],
  data() {
    return {
      dialogVisible: false,
      INFO,
      id: null,
      form: {},
      TABLECOLUMN,
      tableData: [],
      loading: {
        init: false,
      },
    };
  },
  methods: {
    getLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
    async init(row) {
      this.dialogVisible = true;
      this.id = row.id;
      const { goods, ...info } = await receiptOrderDetail({
        receiptOrderId: row.id,
      });
      this.form = { ...info, receiptType: StoreBillTypeText[info.receiptType] };
      this.tableData = goods.map((item) => {
        const bookingNum = item.bookingNum || 0;
        const acceptanceNum = item.acceptanceNum || 0;
        return {
          ...item,
          bookingNumStr: this.calculateUnit(item.bookingNum, item),
          acceptanceNumStr: this.calculateUnit(item.acceptanceNum, item),
          diff: this.calculateUnit(Math.abs(bookingNum - acceptanceNum), item),
        };
      });
    },
    calculateUnit(smallUnitNumber, item) {
      return smallUnitNumber && unitNumber.calculate({ ...item, smallUnitNumber }).txt;
    },
    async handleSubmit() {
      await diffComplete({ receiptOrderId: this.id, resourceType: 'PC' });
      this.$message({
        type: 'success',
        message: '操作成功',
      });
      this.onClose();
      this.$emit('onSucceed');
    },
    onClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
