<template>
  <nh-drawer
    v-model="visible"
    v-loading="loading"
    :before-close="handleClose"
    destroy-on-close
    title="材料上传"
    size="704px"
  >
    <div class="alert">
      订单中包含食品相关的货品，请上传每批批次报告和年度型式报告。
    </div>
    <div class="upload-box">
      <nh-upload-attachment
        v-model:upload-file="uploadFile"
        @onRemove="handleRemove"
        @onAllRemove="handleAllRemove"
      />
    </div>
    <el-form
      :model="form"
      label-width="108px"
      label-suffix=":"
    >
      <el-form-item
        label="无材料"
        :span="24"
      >
        <el-checkbox v-model="checked" />
      </el-form-item>
      <el-form-item
        v-if="checked"
        label="原因说明："
        :span="24"
      >
        <el-input
          v-model="form.reason"
          :rows="2"
          type="textarea"
          placeholder="请输入"
        />
      </el-form-item>
    </el-form>

    <template #footer>
      <div class="footer">
        <nh-button
          v-loading="loadingSub"
          type="primary"
          @click="handleSubmit"
        >
          确 定
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>

<script setup>
import { defineExpose, ref, unref } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import useDrawer from './uses/useDrawer';
import useFiles from './uses/uploadFiles';
import formSubmit from './uses/onSubmit';

const {
  visible,
  handleOpen,
  onClose,
} = useDrawer();

const {
  uploadFile,
  getAttachmentFiles,
  handleRemove,
  handleAllRemove,
  getFiles,
} = useFiles();

const {
  onSubmit,
} = formSubmit();

const form = ref({});
const checked = ref(false);
const loadingSub = ref(false);

const init = (row, { fileData = [], reason }) => {
  handleOpen();
  checked.value = !!reason;
  form.value = {
    ...row,
    reason,
  };
  getAttachmentFiles(row, fileData);
};
const router = useRouter();

const handleClose = () => {
  form.value = {};
  checked.value = false;
  uploadFile.value = [];
  onClose();
};
const handleSubmit = async () => {
  if (!checked.value) {
    form.value.reason = '';
  }
  if (uploadFile.value.length === 0 && !form.value.reason) {
    ElMessage({
      message: '必须已勾选无材料且填写了原因说明。',
      type: 'warning',
    });
    return;
  }

  loadingSub.value = true;
  try {
    await onSubmit(unref(form), getFiles());
    onClose();
    router.push({
      path: 'acceptance/confirm',
      query: {
        id: form.value.receiptOrderNo,
      },
    });
  } finally {
    loadingSub.value = false;
  }
};
defineExpose({
  init,
});
</script>
<style lang="scss" scoped>
.alert {
  box-sizing: border-box;
  margin-bottom: 16px;
  padding-left: 16px;
  line-height: 48px;
  background-color: #f9f1ec;
  border: 1px solid #fbbb98;
  border-radius: 4px;
  font-size: 14px;
  color: #fe5e08;
}

.upload-box {
  min-height: 120px;
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
</style>
