<template>
  <div>
    <pl-block>
      <PlForm
        v-model="searchQuery"
        mode="search"
        :fields="serchFields"
        @submit="handleQuery"
        @reset="handleQuery"
      >
        <template #shipperId>
          <nh-shipper-selector
            v-model:shipperId="searchQuery.shipperId"
          />
        </template>
      </PlForm>
    </pl-block>
    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        :loading="loading.getTableData"
        :data="tableData"
        :show-table-setting="true"
        :columns="TABLECOLUMN"
      >
        <template #action="{row}">
          <el-button
            v-if="row.diffState"
            type="text"
            @click="handleDiffState(row)"
          >
            差异确认
          </el-button>
          <el-button
            v-else
            type="text"
            @click="handleAcceptance(row)"
          >
            验收
          </el-button>
        </template>
        <template #receiptOrderNo="{row}">
          <nh-link
            :to="{}"
            :text="row.receiptOrderNo"
            @click="handleDetails(row)"
          />
          <div v-if="row.isImport" class="import-box">
            进口
          </div>
        </template>
      </PlTable>
    </pl-block>
    <FourCardTest
      ref="fourCardTest"
      @handleSuccess="fourCardSuccess"
    />
    <UploadFiles ref="uploadFilesFoods" />
    <acceptance-diff ref="diff" @onSucceed="getTableData" />
    <nh-drawer
      v-model="drawerVisible"
      :before-close="handleClose"
      destroy-on-close
      title="订单基本信息"
      size="1111px"
      :track="{
        name_zh: '入库管理/验收/抽屉-详情',
        trackName: $route.path
      }"
    >
      <InWarehouseDrawer
        v-if="drawerVisible"
        :inbound-order-id="inboundOrderId"
      />
    </nh-drawer>
  </div>
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';

import { YMDHMS } from '@/constant/timeFormat';
import InWarehouse from '@/constant/pageNames/inWarehouse';
import {
  DICTIONARY, TABLECOLUMN, serchFields, RECEIPT_TYPE,
} from './fileds';
import Enum from './utils';
import {
  timeFormat,
} from '../inbound-order/utils';
import { receiptOrderPage } from './api';
import { fileAttachmentQuery } from '../inbound-order/api';
import AcceptanceDiff from './components/AcceptanceDiff.vue';
import FourCardTest from '../inbound-order/fourCardTest/index.vue';
import InWarehouseDrawer from '../inbound-order/detail/index.vue';
import UploadFiles from './components/UploadFiles/UploadFiles.vue';
// 业务类型
const BIZ_TYPE_ENUM = {
  RECEIPT_ORDER: 'RECEIPT_ORDER', // 入库单
  DELIVER_ORDER: 'DELIVER_ORDER', // 出库单
};
export default {
  name: InWarehouse.ACCEPTANCE,
  components: {
    AcceptanceDiff,
    FourCardTest,
    InWarehouseDrawer,
    UploadFiles,
  },
  mixins: [loadingMixin],
  provide: {
    nameZh: '入库管理/验收/',
  },
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      DICTIONARY,
      RECEIPT_TYPE,
      tableData: [],
      searchQuery: {
        shipperId: null,
        ignoreReceiptStatus: [
          'DONE_PUT_ON_SHELVES', 'CANCELLED',
          'PRE_PUT_ON_SHELVES', 'PRE_CHECK_IN',
          'PRE_AUDIT', 'PRE_STORE',
        ],
      },
      pagination: {
        total: 0,
        size: 50,
        page: 1,
        sort: 'createTime__DESC',
      },
      loading: {
        getTableData: false,
      },
      inboundOrderId: 0,
      drawerVisible: false,
      formFile: {
        fileData: [],
        reason: '',
      },
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      this.tableData = [];
      const [beforeArrivalTime, afterArrivalTime] = this.searchQuery.arrivalTime || [];
      const [beforeCreateTime, afterCreateTime] = this.searchQuery.createTime || [];
      const params = {
        ...this.searchQuery,
        beforeArrivalTime,
        afterArrivalTime,
        beforeCreateTime,
        afterCreateTime,
      };
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
        sort: 'createTime__DESC',
      };
      const resp = await receiptOrderPage(pagination, params);
      this.tableData = resp.records.map((item) => ({
        ...item,
        receiptType: Enum(RECEIPT_TYPE)[item.receiptType],
        createTime: this.momentTime(item.createTime),
        arrivalTime: this.momentTime(item.arrivalTime),
        endAcceptanceTime: this.momentTime(item.endAcceptanceTime),
      }));
      this.pagination.total = resp.total;
    },
    momentTime(time) {
      return time && moment(time).format(YMDHMS);
    },
    async  handleAcceptance(row) {
      if (row.isImport) {
        await this.fileAttachmentQuery(row);
        this.$refs.fourCardTest.init(row, this.formFile.fileData);
      } else if (row.isFood && row.receiptType === '订单入库') {
        // 入库单为订单入库且包含货品类别为“食品”和“食品相关”货品时
        await this.fileAttachmentQuery(row);
        this.$refs.uploadFilesFoods.init(row, this.formFile);
      } else {
        this.$router.push({
          path: 'acceptance/confirm',
          query: {
            id: row.receiptOrderNo,
          },
        });
      }
    },
    // 查询订单附件列表
    async fileAttachmentQuery(rowData) {
      const { fileAttachments = [], reason } = await fileAttachmentQuery(
        { bizId: rowData.id, bizTypeEnum: BIZ_TYPE_ENUM.RECEIPT_ORDER },
      );
      this.formFile.fileData = fileAttachments.map((item) => ({
        ...item, url: item.filePath, fileTime: timeFormat(item.updateTime),
      }));
      this.formFile.reason = reason;
    },
    fourCardSuccess(row) {
      this.$router.push({ path: 'acceptance/confirm', query: { id: row.receiptOrderNo } });
    },
    handleDiffState(row) {
      this.$refs.diff.init(row);
    },
    handleDetails({ id }) {
      this.inboundOrderId = id;
      this.drawerVisible = true;
    },
    handleClose() {
      this.drawerVisible = false;
      this.getTableData();
    },
  },
};
</script>
<style scoped lang="scss">
.import-box {
  padding: 0 4px;
  font-size: 12px;
  font-weight: 400;
  color: #fe5e08;
  background: #f9f1ec;
  border-radius: 4px;
  border: 1px solid #fbbb98;
  display: inline-block;
}
</style>
