import { attachmentBatchSave } from '../../../api';
import { BIZ_TYPE_ENUM } from '../../../../inbound-order/constant';

const formSubmit = () => {
  const onSubmit = async (form, fileds) => {
    await attachmentBatchSave({
      ...form,
      bizId: form.id,
      bizTypeEnum: BIZ_TYPE_ENUM.RECEIPT_ORDER,
      attachmentFileReqs: fileds,
    });
  };
  return {
    onSubmit,
  };
};

export default formSubmit;
