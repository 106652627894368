// import data from '../data';
import { ref } from 'vue';

const handleDraver = () => {
  const visible = ref(false);
  const handleOpen = () => {
    visible.value = true;
  };
  const onClose = () => {
    visible.value = false;
  };
  return {
    visible,
    handleOpen,
    onClose,
  };
};

export default handleDraver;
