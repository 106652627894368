import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:入库信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13272
 */
export function receiptOrderPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/page', data, {
    params,
  });
}
/**
 * @description:差异确认
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14330
 */
export function diffComplete(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/acceptance/diff/complete', data);
}
/**
 * @description:入库信息表 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13274
 */
export function receiptOrderDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/detail', data);
}
/**
 * @description:保存文件数据
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20851
 */
export function attachmentBatchSave(data) {
  return wmsPlusHttp.post('/warehouse_management_system/file_attachment/batch/save', data);
}
